<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <div class="text-center" v-if="loading">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-form
              v-else
              ref="formRef"
              @submit.prevent="saveData"
            >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                  <v-card
                    :title="form.aktion == 'editSegmentCheck' ? 'Segment bearbeiten' : 'Segment erstellen'"
                  >
                    <v-card-title v-if="form.aktion == 'editSegmentCheck'">Segment bearbeiten</v-card-title>
                    <v-card-title v-else>Segment erstellen</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="form.mainData.titel"
                            label="Titel"
                            type="text"
                            outlined
                            dense
                            hide-details="auto"
                            placeholder="Titel"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-btn color="error" class="addButton" @click="testSegment">
                            TEST
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card class="mt-5">
                    <v-card-text v-if="form.mainData.einstellungenJson.length > 0">
                      <v-card v-for="(groupItem,groupKey) in form.mainData.einstellungenJson" :key="groupKey" :class="(groupKey != getLastKeyOf(form.mainData.einstellungenJson) ? 'mb-7' : '') + ' ' + (groupKey != getFirstKeyOf(form.mainData.einstellungenJson) ? 'pt-5' : '')" class="filterCard " outlined>
                        <v-card-title class="">
                          <template v-if="groupKey != getFirstKeyOf(form.mainData.einstellungenJson)">
                            <v-radio-group
                              v-model="groupItem.connectedWith"
                              class="mt-0 connector connector--outside hideRadioInputs"
                              hide-details
                            >
                              <div class="d-flex flex-wrap demo-space-x align-center justify-center">
                                <v-radio
                                  label="AND"
                                  color="primary"
                                  value="AND"
                                >
                                  <template v-slot:label>
                                    <v-btn class="ma-2  fill-height" :class="groupItem.connectedWith == 'AND' ? 'error' : 'secondary'">
                                      AND
                                    </v-btn>
                                  </template>
                                </v-radio>
                                <v-radio
                                  label="OR"
                                  color="secondary"
                                  value="OR"
                                >
                                  <template v-slot:label>
                                    <v-btn class="ma-2  fill-height" :class="groupItem.connectedWith == 'OR' ? 'error' : 'secondary'">
                                      OR
                                    </v-btn>
                                  </template>
                                </v-radio>
                              </div>
                            </v-radio-group>
                          </template>

                          <v-col cols="12 " md="12" class="d-flex justify-start fill-height pa-0">
                            <v-icon
                              size="20"
                              @click="removeFilterGroup(groupKey)"
                            >
                              {{ icons.mdiWindowClose }}
                            </v-icon>
                          </v-col>
                        </v-card-title>
                        <v-card-text>
                          <v-card v-for="(item,itemKey) in groupItem.filters" :key="itemKey" class="pb-5" :class="itemKey != getLastKeyOf(groupItem.filters) ? 'mb-7' : ''">
                            <v-card-title class="pb-1 pl-2 pr-0" :class="itemKey == getFirstKeyOf(groupItem.filters) ? 'pt-2' : ''">
                              <v-col cols="12" md="4">
                                <label>{{getColumnValueOfField(item.field,'text')}}</label>
                              </v-col>

                              <v-col cols="12 " md="8" class="d-flex align-center justify-end fill-height pr-5">
                                <v-icon
                                  size="20"
                                  class="removeIcon"
                                  @click="removeFilter(groupKey,itemKey)"
                                >
                                  {{ icons.mdiWindowClose }}
                                </v-icon>
                              </v-col>
                              <template v-if="itemKey != getFirstKeyOf(groupItem.filters)">
                                <v-radio-group
                                  v-model="item.connectedWith"
                                  class="mt-0 connector connector--inside hideRadioInputs"
                                  hide-details
                                >
                                  <div class="d-flex flex-wrap demo-space-x align-center justify-center">
                                    <v-radio
                                      color="primary"
                                      value="AND"
                                    >
                                      <template v-slot:label>
                                        <v-btn class="ma-2  fill-height" :class="item.connectedWith == 'AND' ? 'error' : 'secondary'">
                                          AND
                                        </v-btn>
                                      </template>
                                    </v-radio>
                                    <v-radio
                                      color="secondary"
                                      value="OR"
                                    >
                                      <template v-slot:label>
                                        <v-btn class="ma-2  fill-height" :class="item.connectedWith == 'OR' ? 'error' : 'secondary'">
                                          OR
                                        </v-btn>
                                      </template>
                                    </v-radio>
                                  </div>
                                </v-radio-group>
                              </template>
                            </v-card-title>
                            <v-card-text>
                              <v-row>
                                <template v-if="getColumnValueOfField(item.field,'type') == 'g1'">
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <v-radio-group
                                      v-model="item.operator"
                                      class="mt-0"
                                      hide-details
                                    >
                                      <div class="d-flex flex-wrap demo-space-x">
                                        <v-radio
                                          label="einschließen"
                                          color="error"
                                          value="IN"
                                        ></v-radio>
                                        <v-radio
                                          label="ausschließen"
                                          color="error"
                                          value="NOT IN"
                                        ></v-radio>
                                      </div>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12" md="8">
                                    <template v-if="item.field == 'land'">
                                      <v-select
                                        class=""
                                        v-model="item.value"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        multiple
                                        chips
                                        :items="countries"
                                        item-text="de"
                                        item-value="iso6391"
                                        label="Land"
                                      ></v-select>
                                    </template>
                                    <template v-else-if="item.field == 'geschlecht'">
                                      <v-select
                                        class=""
                                        v-model="item.value"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        :items="genders"
                                        item-text="label"
                                        item-value="value"
                                        label="Geschlecht"
                                      ></v-select>
                                    </template>
                                    <template v-else-if="item.field == 'tags'">
                                      <v-select
                                        class=""
                                        v-model="item.value"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        multiple
                                        chips
                                        :items="tags"
                                        item-text="displayName"
                                        item-value="idNipTag"
                                        label="Tags"
                                      ></v-select>
                                    </template>
                                    <template v-else-if="item.field == 'themenbereiche'">
                                      <v-select
                                        class=""
                                        v-model="item.value"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        multiple
                                        chips
                                        :items="topics"
                                        item-text="displayName"
                                        item-value="idNipThemenbereich"
                                        label="Themenbereiche"
                                      ></v-select>
                                    </template>
                                    <template v-else>
                                      <v-text-field
                                        v-model="item.value"
                                        label="Wert"
                                        type="text"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        placeholder="Wert"
                                      ></v-text-field>
                                    </template>
                                  </v-col>
                                </template>
                                <template v-else-if="getColumnValueOfField(item.field,'type') == 'g2'">
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <v-select
                                      class=""
                                      v-model="item.operator"
                                      hide-details="auto"
                                      outlined
                                      dense
                                      :items="operators"
                                      item-text="value"
                                      item-value="value"
                                      label="Operator"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" md="8">
                                    <v-text-field
                                      v-model="item.value"
                                      label="Wert"
                                      type="text"
                                      outlined
                                      dense
                                      hide-details="auto"
                                      placeholder="Wert"
                                    ></v-text-field>
                                  </v-col>
                                </template>
                                <template v-else-if="getColumnValueOfField(item.field,'type') == 'g3'">
                                  <v-col
                                    cols="12"
                                    md="4"
                                  >
                                    <v-radio-group
                                      v-model="item.operator"
                                      class="mt-0"
                                      hide-details
                                    >
                                      <div class="d-flex flex-wrap demo-space-x">
                                        <v-radio
                                          label="einschließen"
                                          color="error"
                                          value="IN"
                                        ></v-radio>
                                        <v-radio
                                          label="ausschließen"
                                          color="error"
                                          value="NOT IN"
                                        ></v-radio>
                                      </div>
                                    </v-radio-group>
                                  </v-col>
                                  <v-col cols="12" md="12">
                                    <v-textarea
                                      v-model="item.value"
                                      outlined
                                      label="Wert (Mit Zeilenumbruch trennen)"
                                      rows="6"
                                      hide-details="auto"
                                      placeholder="Wert (Mit Zeilenumbruch trennen)"
                                    ></v-textarea>
                                  </v-col>
                                </template>

                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                        <v-card-actions dense class="justify-end">
                          <v-btn color="error" class="addButton" @click="openFieldDialog(groupKey)">
                            <v-icon size="30">{{icons.mdiPlus }}</v-icon> Filter
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-card-text>
                    <v-card-actions dense>
                      <v-btn color="error" class="addButton" @click="addFilterGroup">
                        <v-icon size="30">{{icons.mdiPlus }}</v-icon> Filtergruppe
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                  <router-link :to="'/segment'">
                    <v-btn
                      color=""
                      class="mx-2"
                    >
                      Zurück
                    </v-btn>
                  </router-link>
                  <v-btn color="primary" type="submit">
                    Speichern
                  </v-btn>

                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>


    <v-dialog
      v-model="showFieldDialog"
      width="700px"
    >
      <v-card>
        <v-card-title class="justify-center">Filter wählen</v-card-title>
        <v-card-text
          class="text-center"
        >
          <v-row>
            <v-col cols="12 justify-center">
              <v-radio-group v-model="selectedNewField" class="hideRadioInputs justify-center align-center fill-height" row>
                <v-radio v-for="(item,key) in fields" :key="key" :value="item.value">
                  <template v-slot:label>
                    <v-btn block class="ma-2  fill-height" :class="selectedNewField == item.value ? 'error' : ''">
                      {{item.text}}
                    </v-btn>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="addFilter(selectedGroupKey)"
          >
            Hinzufügen
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="closeFieldDialog"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiWindowClose,
  mdiPlus
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";

export default {
  components: {

  },
  setup(props) {

    const userTab = ref(null)
    const tabs = ref([
      { icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
    ])

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    const formRef = ref(null)
    const form = ref({})
    const showFieldDialog = ref(false)
    const selectedNewField = ref("")
    const selectedGroupKey = ref(0)
    const countries = ref([])
    const topics = ref([])
    const tags = ref([])

    const genders = ref([
      {label:'Männlich',value:'m'},
      {label:'Weiblich',value:'w'},
    ]);


    const openFieldDialog = (groupKey) => {
      showFieldDialog.value = true;
      selectedGroupKey.value = groupKey;
    }

    const closeFieldDialog = () => {
      showFieldDialog.value = false;
      selectedGroupKey.value = 0;
      selectedNewField.value = "";
    }

    const fields = ref([
      {text:"Land",value:"land",type:"g1"},
      {text:"Geschlecht",value:"geschlecht",type:"g1"},
      {text:"Alter",value:"alter",type:"g2"},
      {text:"Plz",value:"plz",type:"g2"},
      {text:"Ort",value:"ort",type:"g3"},
      {text:"Kundenkennung",value:"kundenkennung",type:"g3"},
      {text:"Tags",value:"tags",type:"g1"},
      {text:"Themenbereich",value:"themenbereiche",type:"g1"},
      {text:"Tage seit Eintragung",value:"tageSeitEintragung",type:"g1"},
      {text:"Tage seit letztem Klick",value:"tageSeitLetztemKlick",type:"g2"},
      {text:"Anzahl Klicks",value:"anzahlKlicks",type:"g2"},
      {text:"Klickrate",value:"conversionKlicks",type:"g2"},
    ]);

    const operators = ref([
      {value:"="},
      {value:"!="},
      {value:">"},
      {value:">="},
      {value:"<"},
      {value:"<="},
    ]);

    const defaultFilter = {
      connectedWith: "AND",
      field: "",
      operator: "",
      value: ""
    }

    const getFirstKeyOf = (array) => {
      for (let i in array) return i;
    }

    const getLastKeyOf = (array) => {
      let lastIndex = -1;
      array.forEach((item,key) => {
        lastIndex = key;
      })
      return lastIndex;
    }

    const getColumnValueOfField = (value,column) => {
      let columnValue = "";
      fields.value.forEach(item => {
        if(item.value == value){
          columnValue = item[column];
        }
      })
      return columnValue;
    }

    const testSegment = () => {
      const fd = new FormData();
      fd.append('aktion','testSegment');
      fd.append('mainData[einstellungenJson]',JSON.stringify(form.value.mainData.einstellungenJson));
      axios
        .post('/api/ajaxServer/',fd)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    const addFilterGroup = () => {
      form.value.mainData.einstellungenJson.push({connectedWith:"AND",filters:[]});
    }

    const addFilter = (groupKey) => {
      const newFilter = JSON.parse(JSON.stringify(defaultFilter))
      newFilter.field = selectedNewField.value
      form.value.mainData.einstellungenJson[groupKey].filters.push(newFilter);
      closeFieldDialog();
    }

    const removeFilter = (groupKey,itemKey) => {
      form.value.mainData.einstellungenJson[groupKey].filters = form.value.mainData.einstellungenJson[groupKey].filters.filter ((x,i) => i != itemKey)
    }

    const removeFilterGroup = (groupKey) => {
      form.value.mainData.einstellungenJson = form.value.mainData.einstellungenJson.filter ((x,i) => i != groupKey)
    }

    form.value = {
      mainData : {
        idNipSegment : router.currentRoute.params.idNipSegment,
        titel : '',
        einstellungenJson:[]
      },
      aktion: (router.currentRoute.params.idNipSegment !== undefined ? 'editSegmentCheck' : 'createSegmentCheck'),
    };
    const loading = ref(true)

    onMounted(()=>{

      axios.get('/api/ajaxServer/?aktion=fetchCountries').then((response) => countries.value = response.data.countries)
      axios.get('/api/ajaxServer/?aktion=fetchTopics').then((response) => topics.value = response.data.topics)
      axios.get('/api/ajaxServer/?aktion=fetchTags').then((response) => tags.value = response.data.tags)
      axios.get('/api/ajaxServer/?aktion=fetchAttributesSettings').then((response) => {
        response.data.attributes.forEach(item => {
          fields.value.push({
            text : item.anzeigeName,
            value : item.feld,
            type : "g2"
          })
        })
      })

      if(form.value.aktion == 'editSegmentCheck'){
        axios.post('/api/segment/', {
          aktion: 'editSegment',
          mainData:{
            idNipSegment : router.currentRoute.params.idNipSegment,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {
              form.value.mainData = response.data.segment;
            } else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;
          })
      }
      else{
        loading.value = false;
      }
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid) return
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion',form.value.aktion);
      fd.append('mainData[idNipSegment]',form.value.mainData.idNipSegment);
      fd.append('mainData[titel]',form.value.mainData.titel);
      fd.append('mainData[einstellungenJson]',JSON.stringify(form.value.mainData.einstellungenJson));

      axios
        .post('/api/segment/',fd)
        .then(response => {
          if(response.data.valid === true){
            router.push('/segment',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    return {
      userTab,
      tabs,
      form,
      formRef,
      saveData,
      loading,
      fields,
      operators,
      addFilter,
      addFilterGroup,
      removeFilter,
      removeFilterGroup,
      defaultFilter,
      getFirstKeyOf,
      getLastKeyOf,
      testSegment,
      countries,
      genders,
      topics,
      tags,
      showFieldDialog,
      selectedNewField,
      openFieldDialog,
      closeFieldDialog,
      selectedGroupKey,
      getColumnValueOfField,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiWindowClose,
        mdiPlus ,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs{
  display: none;
}
#user-tabs-content{
  margin:0 !important;
}
.filterCard {
  background: #f2f2f2 !important;
  border: 1px solid #f4f4f4 !important;
}


.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0px;
}


.hideRadioInputs .v-input--selection-controls__input{
  display:none;
}

.hideRadioInputs .v-input--radio-group__input {
  justify-content: center;
}

.hideRadioInputs .v-radio {
  min-width: 135px !important;
  min-height: 70px !important;
  margin-bottom:20px;

}

.hideRadioInputs .v-btn:not(.v-btn--round).v-size--default,
.v-input--selection-controls .v-radio > .v-label {
  height: 100% !important;
}


.connector {
  position: absolute;
}
.connector--outside {
  top: -39px;
  left: 0px;
}
.connector--inside{
  top: -37px;
  right:5px;
}
.connector .v-radio{
  margin:0 !important;
  margin-right: -30px !important;
}
.connector.hideRadioInputs .v-input--radio-group__input .v-btn{
  height:46px !important;
  width:30px !important;
}

.filterCard.mb-15{
  margin-bottom:100px !important;
}

</style>
